header {
    .use-dropdown-menu {
        position: relative;
        button {
            background: transparent;
            border: none;
            cursor: pointer;
        }
        a:focus {
            outline: 1px dotted rgba($dark-text, 0.3);
            text-decoration: underline;
        }
        .menu-wrapper {
            display: none;
            position: absolute;
            bottom: 0;
            left: 0;
            transform: translate(0, 100%);
            background-color: $white;
            box-shadow: 0px 1px 0px rgba($color: $black, $alpha: 0.12);
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            z-index: 10;
            border-radius: 6pt;
            padding: 5px 0;
            &.visible {
                display: flex;
            }
            a {
                white-space: nowrap;
            }
        }
    }
}