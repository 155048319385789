@font-face {
    font-family: $GilroySemibold;
    src: local($GilroySemibold), url('../fonts/Gilroy-SemiBold.woff') format('woff');
}

@font-face {
    font-family: $GilroyRegular;
    src: local($GilroyRegular), url('../fonts/Gilroy-Regular.woff') format('woff');
}

@font-face {
    font-family: $GilroyMedium;
    src: local($GilroyMedium), url('../fonts/Gilroy-Medium.woff') format('woff');
}

@font-face {
    font-family: $GilroyBold;
    src: local($GilroyBold), url('../fonts/Gilroy-Bold.woff') format('woff');
}