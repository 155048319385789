span.highlighted {
    color: $primary-red;
}

span.reverse-highlighted {
    color: $dark-text;
}

.container {
    width: 100%;
    margin: auto;
    max-width: 1382px;
    padding-left: 35px;
    padding-right: 35px;
    .row {
        margin: auto;
        max-width: 90vw;
    }
}