section.process-consulting-tabs {
    width: 100%;
    display: grid;
    padding: 0;
    overflow-x: hidden;
    .container {
        padding: 0;
    }
    .react-tabs {
        ul {
            display: flex;
            justify-content: center;
            list-style: none;
            padding: 0;
            margin: 0;
            user-select: none;
            li {
                font-family: $GilroyMedium;
                font-size: 24px;
                line-height: 23pt;
                cursor: pointer;
                padding: 24px;
                color: $dark-text;
                &[aria-selected="true"] {
                    color: $primary-red;
                    border-bottom: 2px solid $primary-red;
                }
            }
        }
        .react-tabs__tab-panel {
            background-image: radial-gradient(100% 100% at 40% 85%, #d9e4ec 19%, #e9e9ef 39.25%, #f2f0f3 59.5%, #ffffff 100%);
            // full-width
            width: 100vw;
            position: relative;
            left: 50%;
            right: 50%;
            margin-left: -50vw;
            margin-right: -50vw;
            .container {
                padding: 123px 0 106px;
                .row {
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    img {
                        flex-basis: 534px;
                        flex-grow: 0;
                        flex-shrink: 1;
                        &.left {
                            margin-right: 85px;
                            order: 1;
                        }
                        &.right {
                            margin-left: 85px;
                            order: 3;
                        }
                    }
                    .content-wrapper {
                        flex-basis: 641px;
                        flex-grow: 0;
                        flex-shrink: 1;
                        order: 2;
                        color: $dark-text;
                        h1 {
                            font-family: $GilroyBold;
                            font-size: 50px;
                            line-height: 54pt;
                            margin: 0 0 13px;
                            user-select: none;
                        }
                        p {
                            font-family: $GilroyMedium;
                            font-size: 20px;
                            line-height: 26pt;
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    section.process-consulting-tabs {
        .container {
            padding: 0;
            .row {
                max-width: unset;
            }
        }
        .react-tabs {
            ul {
                li {
                    font-size: 15px;
                    line-height: 20pt;
                    padding: 12px;
                }
            }
            .react-tabs__tab-panel {
                .container {
                    padding: 60px 20px 38px;
                    .row {
                        max-width: unset;
                        flex-direction: column;
                        img {
                            flex-basis: unset;
                            width: 100%;
                            max-width: 400px;
                            &.left {
                                margin: 0 auto 40px;
                            }
                            &.right {
                                margin: 0 auto 40px;
                                order: 1;
                            }
                        }
                        .content-wrapper {
                            flex-basis: unset;
                            h1 {
                                font-size: 30px;
                                line-height: 42pt;
                                margin: 0 0 16px;
                            }
                            p {
                                font-size: 15px;
                                line-height: 21pt;
                            }
                        }
                    }
                }
            }
        }
    }
}