section.contact-form {
    width: 100%;
    height: 400px;
    margin-bottom: 400px;
    background: radial-gradient(circle at 45% 90%, rgb(217, 228, 236) 0%, rgb(255, 255, 255) 100%);
    .wrapper {
        padding: 74px 0 0;
        .container {
            padding: 50px 125px;
            background: $white 0% 0% no-repeat padding-box;
            box-shadow: 8px 8px 0px #D9E4EC;
            border: 2px solid #D9E4EC;
            border-radius: 10pt;
            max-width: calc(100% - 40px);   
            .message-box {
                display: flex;
                justify-content: center;               
                text-align: left;
                font-family: $GilroyRegular;
                font-size: 20px;
                color: $dark-text;
                padding: 20px;
                margin-bottom: 20px;
                &.sucess {
                    background-color: #DFF2BF;
                }
                &.error {
                    background-color: #FFBABA;
                }
            }
            .row {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                .text-wrapper {
                    max-width: 50%;
                    flex-basis: 489px;
                    margin-right: 81px;
                    p {
                        text-align: left;
                        font-family: $GilroyMedium;
                        font-size: 20px;
                        line-height: 26pt;
                        color: $dark-text;
                        margin: 0 0 80px;
                    }
                    .contact-info {
                        a {
                            text-align: left;
                            font: normal normal bold 20px/26px $GilroyBold;
                            color: $dark-text;
                            text-decoration: none;
                            display: flex;
                            img {
                                margin-right: 14px;
                            }
                        }
                        .email-info {
                            margin-bottom: 20px;
                        }
                    }
                }
                .form-wrapper {
                    text-align: left;
                    max-width: 50%;
                    flex-basis: 489px;
                    h1 {
                        font-size: 50px;
                        text-align: left;
                        line-height: 54pt;
                        color: $dark-text;
                        margin: 40px 0 13px;
                        font-family: $GilroyBold;
                    }
                    h6.form-title {
                        text-align: left;
                        font: normal normal bold 20px/26px $GilroyBold;
                        color: $dark-text;
                        margin: 0 0 25px;
                        padding: 0;
                    }
                    form {
                        .datos-container {
                            display: grid;
                            grid-template-columns: 1fr 1fr;
                            grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
                            gap: 20px 20px;
                            grid-auto-flow: row;
                            grid-template-areas: "name company" "phone email" "message message" "message message" "message message" ". submit";
                            .company {
                                grid-area: company;
                            }
                            .email {
                                grid-area: email;
                            }
                            .submit {
                                grid-area: submit;
                                display: flex;
                                justify-content: flex-end;
                                align-items: flex-end;
                            }
                            .message {
                                grid-area: message;
                            }
                            .name {
                                grid-area: name;
                            }
                            .phone {
                                grid-area: phone;
                            }
                        }
                    }
                    input[type="text"],
                    input[type="email"],
                    input[type="number"] {
                        width: 100%;
                        background: $white 0% 0% no-repeat padding-box;
                        border: 1px solid $gray;
                        border-radius: 6px;
                        opacity: 1;
                        height: 48px;
                        min-width: 180px;
                        &::placeholder {
                            text-align: left;
                            font-family: $GilroyMedium;
                            font-size: 16px;
                            letter-spacing: 0px;
                            color: $dark-text;
                            opacity: 0.7;
                            padding-left: 10px;
                        }
                    }
                    textarea {
                        background: #FFFFFF 0% 0% no-repeat padding-box;
                        border: 1px solid #18183D33;
                        border-radius: 6px;
                        opacity: 1;
                        resize: none;
                        &::placeholder {
                            font-family: $GilroyMedium;
                            font-size: 16px;
                            padding-left: 10px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1400px) {
    section.contact-form {
        .wrapper {
            .container {
                padding: 70px 35px;
                max-width: calc(100% - 40px);
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    section.contact-form {
        height: 650px;
        margin-bottom: 550px;
        .wrapper {
            .container {
                padding: 50px 16px;
                .row {
                    flex-direction: column;
                    .text-wrapper {
                        max-width: unset;
                        flex-basis: unset;
                        margin: 0 0 60px;
                        p {
                            font-size: 15px;
                            line-height: 21pt;
                            margin: 0 0 44px;
                        }
                        .contact-info {
                            a {
                                font: normal normal bold 15px/21px $GilroyBold;
                            }
                            .email-info {
                                margin-bottom: 11px;
                            }
                        }
                    }
                    .form-wrapper {
                        max-width: unset;
                        flex-basis: unset;
                        width: 100%;
                        h6.form-title {
                            font: normal normal bold 15px/21px $GilroyBold;
                            margin: 0 0 18px;
                        }
                        form {
                            .datos-container {
                                grid-template-columns: 1fr;
                                grid-template-areas: "name" "company" "phone" "email" "message" "message" "submit";
                            }
                        }
                    }
                }
            }
        }
    }
}