section.simple-content-block {
    padding: 55px 0 146px;
    .container {
        .row {
            img {
                margin-bottom: 8.4px;
            }
            h1 {
                font-family: $GilroyBold;
                font-size: 50px;
                line-height: 54pt;
                margin: 0 0 20.4px;
                color: $dark-text;
            }
            .content-wrapper {
                display: flex;
                justify-content: space-between;
                p {
                    flex-shrink: 1;
                    flex-grow: 1;
                    flex-basis: 50%;
                    font-family: $GilroyMedium;
                    font-size: 20px;
                    line-height: 26pt;
                    color: $dark-text;
                    margin: 0 51px 0 0;
                    &:last-of-type {
                        margin: 0;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    section.simple-content-block {
        padding: 50px 20px 78.6px;
        .container {
            padding: 0;
            .row {
                max-width: unset;
                img {
                    width: 57px;
                    margin: 0;
                }
                h1 {
                    font-size: 30px;
                    line-height: 42pt;
                    margin: 0 0 20px;
                }
                .content-wrapper {
                    flex-direction: column;
                    p {
                        flex-basis: 100%;
                        margin: 0 0 25px 0;
                    }
                }
            }
        }
    }
}