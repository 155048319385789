//**
//**
// Colors
//**
$primary-red: #F93048;
$white: #FFFFFF;
$black: #000000;
$dark-text: #18183D;
$dark-gray: #D9E4EC;
$dark: #18183DB3;
$gray: #18183D33;
//**
//**
// Fonts
//**
$GilroySemibold: 'GilroySemibold';
$GilroyRegular: 'GilroyRegular';
$GilroyMedium: 'GilroyMedium';
$GilroyBold: 'GilroyBold';