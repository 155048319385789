footer.footer-section {
    background: $dark-text url('./../../assets/svg/footer-bg.svg') no-repeat center center;
    section.pre-footer-section {
        text-align: center;
        margin-top: 250px;
        padding-bottom: 250px;
        .container {
            position: relative;
            .big-cta-wrapper {
                width: 100%;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: $primary-red;
                padding: 100px 0;
                border-radius: 10px;
                h1 {
                    max-width: 865px;
                    margin: 0 auto 10px;
                    color: $white;
                    font-size: 50px;
                    line-height: 54pt;
                    font-family: $GilroyBold;
                }
                p {
                    max-width: 770px;
                    margin: 0 auto 30px;
                    color: $white;
                    font-size: 17px;
                    line-height: 23pt;
                    font-family: $GilroyMedium;
                }
                a.cta-btn.btn-wrapper {
                    margin: 0 auto;
                }
            }
        }
    }
    .container {
        padding: 0;
        .footer-content {
            color: $white;
            font-family: $GilroyRegular;
            padding: 100px 0;
            .logo-up-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap-reverse;
                img.footer-logo {
                    width: 243.58px;
                }
                a.go-up-button {
                    background-color: $primary-red;
                    border-radius: 50%;
                    height: 58px;
                    width: 58px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            .footer-grid-area {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                grid-template-rows: 1fr 1fr;
                gap: 0 30px;
                grid-template-areas: "contact-info company-solutions-other ." "copyright company-solutions-other social-media-links";
                .contact-info {
                    grid-area: contact-info;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    a {
                        font-family: $GilroyRegular;
                        font-size: 18px;
                        line-height: 24pt;
                        opacity: 0.9;
                        color: $white;
                        text-decoration: none;
                        &:hover {
                            text-decoration: underline;
                        }
                        &:first-of-type {
                            margin-bottom: 22pt;
                        }
                    }
                }
                .copyright {
                    grid-area: copyright;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    span {
                        font-family: $GilroyRegular;
                        font-size: 18px;
                        line-height: 24pt;
                        opacity: 0.9;
                        color: $white;
                    }
                }
                .social-media-links {
                    grid-area: social-media-links;
                    display: flex;
                    justify-content: flex-end;
                    align-items: flex-end;
                    a {
                        margin-left: 27px;
                    }
                }
                .company-solutions-other {
                    grid-area: company-solutions-other;
                    .footer-content-row {
                        font-family: $GilroyRegular;
                        opacity: 0.9;
                        nav {
                            white-space: nowrap;
                        }
                        h6,
                        a {
                            font-size: 18px;
                            line-height: 24pt;
                            margin: 0;
                        }
                        h6 {
                            color: rgba($color: $white, $alpha: 0.52);
                        }
                        a {
                            color: $white;
                            text-decoration: none;
                        }
                        span.separator {
                            margin: 0 8px;
                            user-select: none;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1400px) {
    footer.footer-section {
        .container {
            padding: 0 35px;
        }
    }
}

@media only screen and (max-width: 768px) {
    footer.footer-section {
        section.pre-footer-section {
            text-align: left;
            margin-top: 0;
            padding-bottom: 0;
            .container {
                .big-cta-wrapper {
                    padding: 72px 20px;
                    position: unset;
                    top: unset;
                    left: unset;
                    transform: unset;
                    border-radius: 0;
                    h1 {
                        font-size: 24px;
                        line-height: 30pt;
                    }
                    p {
                        margin: 0 auto 20px;
                        font-size: 15px;
                        line-height: 20pt;
                    }
                }
            }
        }
        .container {
            padding: 0;
            .footer-content {
                padding: 60px 20px 40px;
                .logo-up-wrapper {
                    margin-bottom: 35px;
                    img.footer-logo {
                        width: 202px;
                    }
                }
                .footer-grid-area {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    .contact-info {
                        margin-bottom: 26px;
                        order: 1;
                        a {
                            font-size: 15px;
                            line-height: 20pt;
                        }
                    }
                    .copyright {
                        order: 4;
                        span {
                            font-size: 15px;
                        }
                    }
                    .social-media-links {
                        order: 2;
                        margin-bottom: 50px;
                        a {
                            margin-left: 0px;
                            margin-right: 27px;
                        }
                    }
                    .company-solutions-other {
                        order: 3;
                        margin-bottom: 50px;
                        .footer-content-row {
                            nav {
                                white-space: unset;
                            }
                            h6,
                            a {
                                font-size: 15px;
                                line-height: 30pt;
                            }
                        }
                    }
                }
            }
        }
    }
}