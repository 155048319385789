section.breadcrumb{
    margin-top: 90px;
    height: 50px;
    background-color: $dark-gray ;
    display: flex;
    align-items: center; 
    padding: 0 40px 0 40px;
    .breadcrumb-text{
        color: $dark-text;
        text-decoration: none;
        font-family: $GilroyMedium;
        font-style: italic;
        font-size: 16px/26px;
       a{
           color: $dark-text;
           text-decoration: none;
           font-family: $GilroyMedium;
           font-style: italic;
           font-size: 16px/26px;
           &.breadcrumb-2{
               opacity: 0.7;
           }
       }

    }
}