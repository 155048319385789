section.improvement-control-section {
    padding: 69.4px 0 100px;
    background-image: radial-gradient(100% 100% at 40% 85%, #d9e4ec 19%, #e9e9ef 39.25%, #f2f0f3 59.5%, #ffffff 100%);
    .container {
        .row {
            display: flex;
            justify-content: center;
            align-items: center;
            .column {
                flex-basis: 580px;
                flex-shrink: 1;
                flex-grow: 1;
                &>img {
                    margin-bottom: 8.4px;
                }
                h1 {
                    font-family: $GilroyBold;
                    font-size: 50px;
                    line-height: 54pt;
                    margin: 0 0 20.4px;
                    color: $dark-text;
                }
                .content-wrapper {
                    p {
                        font-family: $GilroyMedium;
                        font-size: 20px;
                        line-height: 26pt;
                        color: $dark-text;
                        margin: 0;
                    }
                }
            }
            &>img {
                margin: auto 50px;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    section.improvement-control-section {
        padding: 50px 20px;
        .container {
            padding: 0;
            .row {
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                .column {
                    flex-basis: unset;
                    &>img {
                        margin-bottom: 10.9px;
                        width: 58.7px;
                    }
                    h1 {
                        font-size: 30px;
                        line-height: 42pt;
                        margin: 0 0 10px;
                    }
                    .content-wrapper {
                        p {
                            font-size: 15px;
                            line-height: 21pt;
                        }
                    }
                }
                &>img {
                    width: 47.02px;
                    margin: 14px 0;
                    transform: rotate(90deg);
                }
            }
        }
    }
}