section.process-consulting-info {
    width: 100%;
    display: grid;
    padding: 127px 0 124px;
    .container {
        .row {
            max-width: 90vw;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            margin-bottom: 106px;
            .content-wrapper {
                text-align: left;
                p {
                    font-size: 36px;
                    line-height: 42px;
                    color: $dark-text;
                    margin: 0;
                    font-family: $GilroyBold;
                }
            }
            .image-wrapper {
                display: grid;
                img {
                    width: 100%;
                    margin: auto;
                }
            }
            .content-wrapper,
            .image-wrapper {
                max-width: 50%;
                flex-basis: 641px;
                flex-grow: 1;
                flex-shrink: 1;
            }
            .content-wrapper {
                margin-left: 118.2px;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    section.process-consulting-info {
        padding: 80px 20px 113px;
        .container {
            padding: 0;
            .row {
                margin-bottom: 68.3px;
                flex-direction: column;
                max-width: unset;
                margin: 0;
                align-items: unset;
                .content-wrapper {
                    margin: 0;
                    p {
                        font-size: 24px;
                        line-height: 32pt;
                    }
                }
                .content-wrapper,
                .image-wrapper {
                    flex-basis: 100%;
                    max-width: unset;
                }
                .image-wrapper {
                    margin: 0 0 64px;
                    img {
                        max-width: 400px;
                    }
                }
            }
        }
    }
}