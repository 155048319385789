section.solutions-cross-linking {
    padding: 20px 0 101px;
    .container {
        .row {
            display: flex;
            justify-content: center;
            div.shadowable {
                position: relative;
                display: flex;
                .wrapper {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 41.1px 61.9px 24.9px;
                    border-radius: 10pt;
                    // TODO: make this color a variable
                    border: 2px solid #D9E4EC;
                    background-color: $white;
                    z-index: 2;
                    img {
                        width: 221px;
                        margin-right: 77.8px;
                    }
                    .title-cta {
                        h1 {
                            max-width: 450px;
                            margin: 0 0 13px;
                            font-size: 50px;
                            line-height: 54pt;
                            font-family: $GilroyBold;
                            color: $dark-text;
                        }
                    }
                }
                .wrapper-shadow {
                    position: absolute;
                    top: 8px;
                    left: 8px;
                    background-color: #D9E4EC;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    border-radius: 10pt;
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    section.solutions-cross-linking {
        padding: 20px 20px 87px;
        .container {
            padding: 0;
            .row {
                div.shadowable {
                    .wrapper {
                        padding: 25px 10px;
                        img {
                            margin-right: 22px;
                            max-width: 104.62px;
                        }
                        .title-cta {
                            h1 {
                                word-break: break-word;
                                font-size: 24px;
                                line-height: 28pt;
                            }
                            a {
                                max-width: 130px;
                            }
                        }
                    }
                }
            }
        }
    }
}