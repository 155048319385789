.language-container {
    display: flex;
    padding-left: 20px;
}

button.language-selector {
    background-color: transparent;
    border: none;
    color: $primary-red;
    font-size: 18px;
    line-height: 24pt;
    opacity: 0.9;
    cursor: pointer;
    text-transform: capitalize;
    font-family: $GilroyRegular;
    &:hover {
        opacity: 1;
        text-decoration: underline;
    }
}

@media only screen and (max-width: 768px) {
    .language-container {
        position: absolute;
        left: 20px;
        top: 20px;
        z-index: 120;
        padding-left: 0;
    }
}