@keyframes opacity-fade {
    from {
        opacity: 1;
    }
    to {
        opacity: 0.2;
    }
}

section.methodologies-section {
    min-height: 100vh;
    width: 100%;
    display: grid;
    padding: 20px;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    .container {
        max-width: unset;
        .row {
            max-width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            .content-wrapper {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .text {
                    text-align: center;
                    h6 {
                        font-size: 17px;
                        line-height: 23pt;
                        font-family: $GilroyMedium;
                        color: $white;
                        &.section-title {
                            margin: 0 auto 10px;
                        }
                        &.section-description {
                            margin: 0 auto;
                        }
                    }
                    h1 {
                        margin: 0 auto 23px;
                        font-family: $GilroyBold;
                        color: $white;
                        font-size: 50px;
                        line-height: 54pt;
                    }
                }
                .logos-area {
                    flex-basis: 390px;
                    flex-shrink: 0.5;
                    height: 500px;
                    max-height: 90vh;
                    position: relative;
                    img {
                        width: 75px;
                        height: 75px;
                        position: absolute;
                        animation-duration: 2.5s;
                        animation-iteration-count: infinite;
                        animation-direction: alternate;
                        animation-timing-function: ease-out;
                        animation-name: opacity-fade;
                        border-radius: 50%;
                        &.with-bg {
                            height: 65px;
                            width: 65px;
                            border: 5px solid $white;
                            background: $white;
                            object-fit: contain;
                        }
                        &:nth-of-type(2n+1) {
                            opacity: 0.2;
                            animation-direction: alternate-reverse;
                        }
                        &:hover {
                            animation-name: unset;
                            opacity: 1;
                        }
                        &.black-a-logo {
                            left: 0;
                            top: 200px;
                            animation-delay: 0.1s;
                        }
                        &.debian-logo {
                            right: 0;
                            bottom: 15px;
                            animation-delay: 0.6s;
                        }
                        &.red-hat-logo {
                            bottom: 0;
                            left: 0;
                            animation-delay: 0.9s;
                        }
                        &.cisco-logo {
                            top: 260px;
                            right: 10%;
                            animation-delay: 0.5s;
                        }
                        &.cent-os-logo {
                            right: 62px;
                            top: 26px;
                            animation-delay: 0.5s;
                        }
                        &.aws-logo {
                            left: 29px;
                            top: 0;
                            animation-delay: 0.3s;
                        }
                        &.docker-logo {
                            right: 10%;
                            top: 35%;
                            animation-delay: 0.1s;
                        }
                        &.ubuntu-logo {
                            left: 50%;
                            top: 54%;
                            animation-delay: 0.4s;
                        }
                        &.fedora-logo {
                            bottom: 82px;
                            right: 87px;
                            animation-delay: 0.2s;
                        }
                        &.core-os-logo {
                            bottom: 0;
                            left: 0;
                            animation-delay: 0.8s;
                        }
                        &.oracle-logo {
                            left: 80px;
                            bottom: 80px;
                        }
                        &.gcp-logo {
                            bottom: 260px;
                            left: 40%;
                        }
                        &.suse-logo {
                            left: 60px;
                            top: 80px;
                            background: rgba($white, 0.85);
                        }
                        &.f5-logo {
                            left: 0;
                            top: 0;
                        }
                        &.microsoft-logo {
                            right: 0;
                            top: 0;
                        }
                        &.imb-logo {
                            left: 16%;
                            top: 15%;
                        }
                        &.azure-logo {
                            left: 0;
                            top: 30%;
                        }
                    }
                    a:focus img {
                        opacity: 1;
                        animation-name: unset;
                        outline: 2px dashed $primary-red;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    section.methodologies-section {
        padding: 78px 20px 74px;
        .container {
            padding: 0;
            .row {
                .content-wrapper {
                    flex-direction: column;
                    .text {
                        order: 1;
                        text-align: left;
                        margin-bottom: 48px;
                        h6 {
                            font-size: 14px;
                            &.section-description {
                                font-size: 15px;
                            }
                        }
                        h1 {
                            margin: 0 auto 10px;
                            font-size: 34px;
                            line-height: 38pt;
                        }
                    }
                    .logos-area {
                        width: 100%;
                        img {
                            width: 72.89px;
                            height: 72.89px;
                            &.black-a-logo {
                                left: 25%;
                                top: 25%;
                            }
                            &.debian-logo {
                                bottom: 2%;
                            }
                            &.red-hat-logo {
                                bottom: 12%;
                                left: 6%;
                            }
                            &.cisco-logo {
                                top: 30%;
                                right: 15%;
                            }
                            &.cent-os-logo {
                                left: 50%;
                                top: 5%;
                            }
                            &.aws-logo {
                                left: 44%;
                                top: 0;
                            }
                            &.docker-logo {
                                right: 7%;
                                top: 20%;
                            }
                            &.ubuntu-logo {
                                left: 23%;
                                top: 50%;
                            }
                            &.fedora-logo {
                                bottom: 10%;
                                right: 30%;
                            }
                            &.core-os-logo {
                                top: 14%;
                                left: 3%;
                            }
                            &.oracle-logo {
                                left: unset;
                                bottom: unset;
                                right: 5%;
                                top: 5%;
                            }
                            &.gcp-logo {
                                bottom: 10%;
                                left: 40%;
                            }
                            &.suse-logo {
                                left: 50%;
                                top: 50%;
                            }
                            &.f5-logo {
                                left: 0;
                                top: 0;
                            }
                            &.microsoft-logo {
                                right: 0;
                                top: 0;
                            }
                            &.imb-logo {
                                top: unset;
                                bottom: 0;
                            }
                            &.azure-logo {
                                left: 50%;
                                right: 50%;
                            }
                        }
                        &.logos-left {
                            order: 2;
                        }
                        &.logos-right {
                            order: 3;
                        }
                    }
                }
            }
        }
    }
}