section.our-pilars-section {
    width: 100%;
    display: grid;
    padding: 61px 0 117px;
    .container {
        .row {
            h1.our-pilars-heading {
                color: $dark-text;
                font-size: 50px;
                line-height: 42pt;
                text-align: center;
                margin-bottom: 30px;
                font-family: $GilroyBold;
            }
            .pilar-colums {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                .column {
                    flex-basis: 30%;
                    flex-shrink: 0;
                    img.icon {
                        width: 83.28px;
                        height: 83.28px;
                        margin-bottom: 28.36px;
                    }
                    h3.pilar-name {
                        font-size: 36px;
                        line-height: 42pt;
                        color: $dark-text;
                        margin: 0 0 22px;
                        font-family: $GilroyBold;
                    }
                    ul.pilar-details {
                        font-size: 17px;
                        line-height: 20px;
                        color: $dark-text;
                        list-style: none;
                        padding-left: 30px;
                        margin: 0;
                        font-family: $GilroyMedium;
                        li {
                            margin-bottom: 15px;
                            position: relative;
                            &::before {
                                content: "";
                                background-image: url('./../../assets/svg/check-icon.svg');
                                height: 20px;
                                width: 20px;
                                background-position: center;
                                background-size: contain;
                                background-repeat: no-repeat;
                                position: absolute;
                                left: -12px;
                                top: 0;
                                transform: translate(-100%, 0);
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    section.our-pilars-section {
        padding: 80px 20px 70px;
        .container {
            padding: 0;
            .row {
                h1.our-pilars-heading {
                    font-size: 34px;
                    line-height: 36pt;
                    margin-bottom: 22.36px;
                    text-align: left;
                }
                .pilar-colums {
                    flex-direction: column;
                    .column {
                        flex-basis: 100%;
                        margin-bottom: 30px;
                        &:last-of-type {
                            margin-bottom: 0px;
                        }
                        img.icon {
                            margin-bottom: 11.36px;
                        }
                        h3.pilar-name {
                            font-size: 30px;
                            line-height: 36pt;
                            margin: 0 0 13px;
                        }
                        ul.pilar-details {
                            font-size: 15px;
                            line-height: 21pt;
                            li {
                                &::before {
                                    top: -2px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}