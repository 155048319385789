section.about-and-operation-section {
    min-height: 100vh;
    width: 100%;
    display: grid;
    padding: 61px 0 50px;
    .container {
        .row {
            max-width: 90vw;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 60px;
            &:last-of-type {
                margin-bottom: 0px;
            }
            .image-wrapper {
                flex-basis: 645px;
                flex-shrink: 0;
                flex-grow: 0;
                max-width: 50%;
                display: grid;
                img {
                    width: 100%;
                }
            }
            .content-wrapper {
                max-width: 50%;
                h6 {
                    font-size: 17px;
                    line-height: 23pt;
                    color: $dark-text;
                    margin: 0 0 10px;
                    font-family: $GilroyRegular;
                }
                h1 {
                    font-size: 36px;
                    line-height: 42px;
                    color: $dark-text;
                    margin: 0 0 20px;
                    font-family: $GilroyBold;
                }
                p {
                    font-size: 20px;
                    line-height: 26pt;
                    color: $dark-text;
                    margin: 0 0 40px;
                    font-family: $GilroyMedium;
                }
            }
            &:nth-of-type(2n+1) {
                .image-wrapper {
                    order: 1;
                    margin-right: 21px;
                }
                .content-wrapper {
                    order: 2;
                    text-align: left;
                }
            }
            &:nth-of-type(2n) {
                .image-wrapper {
                    order: 2;
                    margin-left: 21px;
                }
                .content-wrapper {
                    order: 1;
                    text-align: right;
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    section.about-and-operation-section {
        padding: 77px 20px 70px;
        .container {
            padding: 0;
            .row {
                flex-direction: column;
                max-width: unset;
                margin-bottom: 100px;
                .image-wrapper {
                    flex-basis: 100%;
                    width: 100%;
                    max-width: unset;
                    margin-bottom: 40px;
                    img {
                        max-width: 400px;
                        margin: 0 auto;
                    }
                }
                .content-wrapper {
                    max-width: unset;
                    h6 {
                        font-size: 14px;
                    }
                    h1 {
                        font-size: 30px;
                        line-height: 35pt;
                        margin: 0 0 15px;
                    }
                    p {
                        font-size: 17px;
                        line-height: 20pt;
                        margin: 0 0 30px;
                    }
                }
                &:nth-of-type(2n+1),
                &:nth-of-type(2n) {
                    .image-wrapper {
                        margin: 0 0 40px;
                        order: 1;
                    }
                    .content-wrapper {
                        order: 2;
                        text-align: left;
                    }
                }
            }
        }
    }
}