section.mission-and-vision {
    min-height: 100vh;
    width: 100%;
    display: grid;
    padding: 74px 0 153px;
    .container {
        .row {
            max-width: 90vw;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 153px;
            &:last-of-type {
                margin-bottom: 0px;
            }
            .image-wrapper {
                flex-basis: 534px;
                flex-shrink: 0;
                flex-grow: 0;
                max-width: 50%;
                display: grid;
                order: 2;
                img {
                    width: 100%;
                }
            }
            .content-wrapper {
                order: 1;
                text-align: left;
                max-width: 50%;
                h1 {
                    font-size: 50px;
                    line-height: 54pt;
                    color: $dark-text;
                    margin: 0 0 13px;
                    font-family: $GilroyBold;
                }
                p {
                    font-size: 26px;
                    line-height: 32pt;
                    color: $dark-text;
                    font-family: $GilroyMedium;
                }
            }
            &:nth-of-type(2n) {
                .image-wrapper {
                    order: 1;
                    margin-right: 88px;
                }
                .content-wrapper {
                    order: 2;
                }
            }
            &:nth-of-type(2n+1) {
                .image-wrapper {
                    margin-left: 88px;
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    section.mission-and-vision {
        padding: 80px 20px 20px;
        .container {
            padding: 0;
            .row {
                flex-direction: column;
                max-width: unset;
                margin-bottom: 80px;
                .image-wrapper {
                    flex-basis: 100%;
                    width: 100%;
                    max-width: unset;
                    margin-bottom: 40px;
                }
                .content-wrapper {
                    max-width: unset;
                    h1 {
                        font-size: 30px;
                        line-height: 42pt;
                        margin: 0 0 16px;
                    }
                    p {
                        font-size: 20px;
                        line-height: 27pt;
                        margin: 0 0 53px;
                    }
                }
                &:nth-of-type(2n+1),
                &:nth-of-type(2n) {
                    .image-wrapper {
                        margin: 0 0 0px;
                        order: 2;
                    }
                    .content-wrapper {
                        order: 1;
                        text-align: left;
                    }
                }
            }
        }
    }
}