a.cta-btn.btn-wrapper {
    display: flex;
    background-color: $dark-text;
    border-radius: 8pt;
    position: relative;
    width: 202px;
    height: 47px;
    box-shadow: inset 0px 0px 0px 2px $primary-red;
    transition: all 0.2s;
    opacity: 0.9;
    button {
        color: $white;
        background-color: $primary-red;
        border: 2px solid $primary-red;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 6pt;
        transition: all 0.2s;
        user-select: none;
        text-align: center;
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        font-size: 18px;
        line-height: 24pt;
        font-family: $GilroyRegular;
        &:focus {
            background-color: $white;
            color: $primary-red;
        }
    }
    &:active {
        opacity: 1;
        background-color: $primary-red;
    }
    &:hover {
        opacity: 1;
        button {
            transform: translate(6px, -6px);
        }
    }
    &.white-bg {
        button {
            font-family: $GilroyMedium;
            color: $primary-red;
            background-color: $white;
        }
        &:hover {
            background-color: $primary-red;
            box-shadow: inset 0px 0px 0px 2px $white;
        }
    }
}