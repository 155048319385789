section.our-culture {
    width: 100%;
    display: grid;
    padding: 0;
    overflow-x: hidden;
    .container {
        max-width: 866px;
        padding: 0;
        .row {
            max-width: 90vw;
            text-align: center;
            h1 {
                font-family: $GilroyBold;
                font-size: 50px;
                line-height: 54pt;
                margin: 0;
                color: $dark-text;
                user-select: none;
            }
        }
    }
    .react-tabs {
        ul {
            display: flex;
            justify-content: center;
            list-style: none;
            padding: 0;
            margin: 0;
            user-select: none;
            li {
                font-family: $GilroyMedium;
                font-size: 24px;
                line-height: 23pt;
                cursor: pointer;
                padding: 24px;
                color: $dark-text;
                &[aria-selected="true"] {
                    color: $primary-red;
                    border-bottom: 2px solid $primary-red;
                }
            }
        }
        .react-tabs__tab-panel {
            background-image: radial-gradient(100% 100% at 40% 85%, #d9e4ec 19%, #e9e9ef 39.25%, #f2f0f3 59.5%, #ffffff 100%);
            // full-width
            width: 100vw;
            position: relative;
            left: 50%;
            right: 50%;
            margin-left: -50vw;
            margin-right: -50vw;
            p {
                font-family: $GilroyMedium;
                font-size: 20px;
                line-height: 26pt;
                margin: 0 auto;
                max-width: 866px;
                &:first-of-type {
                    padding-top: 73px;
                }
                &:last-of-type {
                    padding-bottom: 95px;
                }
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    section.our-culture {
        padding: 80px 0 0;
        .container {
            .row {
                h1 {
                    font-size: 30px;
                    line-height: 42pt;
                    text-align: left;
                }
            }
        }
        .react-tabs {
            ul {
                li {
                    font-size: 15px;
                    line-height: 20pt;
                    padding: 12px 20px;
                }
            }
            .react-tabs__tab-panel {
                p {
                    font-size: 15px;
                    line-height: 21pt;
                    width: 100%;
                    padding: 0 20px;
                    width: 100%;
                    box-sizing: border-box;
                    &:first-of-type {
                        padding-top: 30px;
                    }
                    &:last-of-type {
                        padding-bottom: 121px;
                    }
                }
            }
        }
    }
}