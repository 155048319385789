section.privacy {
    width: 100%;
    display: grid;
    padding: 74px 0 153px;
    .container {
        display: grid;
        grid-template-columns: 1.32fr 1fr;
        grid-template-rows: 100px 1fr;
        gap: 40px 71px;
        grid-auto-flow: row;
        grid-template-areas: "heading privacy-box" "full-text privacy-box";
    }
    .heading {
        grid-area: heading;
        h1 {
            font-size: 50px;
            text-align: left;
            line-height: 54pt;
            color: $dark-text;
            margin: 0 0 13px;
            font-family: $GilroyBold;
        }
        .privacy-info {
            font-size: 18px;
            font-style: italic;
            font-family: $GilroyMedium;
            color: $dark;
            span {
                color: $primary-red;
            }
        }
    }
    button {
        width: 100%;
        display: flex;
        align-items: center;
        font-size: 18px;
        font-family: $GilroyRegular;
        svg {
            padding-left: 5px;
            padding-right: 5px;
        }
    }
    a.cta-btn.btn-wrapper {
        width: 319px;
    }
    .full-text {
        grid-area: full-text;
        &.content-wrapper p {
            font-size: 18px;
            line-height: 26pt;
            color: $dark-text;
            font-family: $GilroyMedium;
        }
    }
    .privacy-box {
        grid-area: privacy-box;
        .privacy-box-container {
            border: 2px $dark-gray solid;
            background: $white;
            box-shadow: 8px 8px 0px $dark-gray;
            border-radius: 10px;
            button {
                width: 100%;
                display: flex;
                align-items: center;
                font-size: 18px;
                font-family: $GilroyRegular;
                svg {
                    padding-left: 5px;
                    padding-right: 5px;
                }
            }
            a.cta-btn.btn-wrapper {
                width: 319px;
            }
            .privacy-box-top {
                background: $dark-text url('./../../assets/svg/privacy-background.svg') 0% 0% no-repeat padding-box;
                padding: 30px;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                h3 {
                    margin: 0;
                    color: $white;
                    font-family: $GilroyRegular;
                    font-size: 24px/34px;
                }
                p {
                    color: $white;
                    font-size: 18px;
                    font-family: $GilroyRegular;
                    opacity: 0.7;
                }
            }
            .privacy-box-bottom {
                padding: 30px;
                .text {
                    color: $dark-text;
                    font-size: 18px;
                    font-family: $GilroyMedium;
                    opacity: 0.7;
                }
                h4 {
                    font-size: 20px;
                    font-family: $GilroyBold;
                    color: $dark-text;
                }
                .list {
                    font-size: 18px;
                    font-family: $GilroyBold;
                    color: $dark-text;
                    .list-item {
                        display: flex;
                        margin-bottom: 25px;
                        span.number {
                            height: 26px;
                            display: flex;
                            flex-basis: 26px;
                            flex-shrink: 0;
                            justify-content: center;
                            align-items: center;
                            border: 2px $dark-text solid;
                            border-radius: 50%;
                            margin-right: 12px;
                        }
                        span.option {
                            a {
                                color: $primary-red;
                                text-decoration: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    section.privacy {
        &>.container {
            padding: 0 20px;
        }
        .container {
            grid-template-columns: 1fr;
            grid-template-rows: auto;
            gap: 25px;
            grid-template-areas: "heading" "privacy-box" "full-text";
        }
        .heading {
            h1 {
                font-size: 30px;
                line-height: 42pt;
                margin: 0 0 6px;
            }
            .privacy-info {
                font-size: 14px;
                line-height: 20pt;
            }
        }
        .full-text {
            &.content-wrapper p {
                font-size: 14px;
                line-height: 20pt;
            }
        }
        .privacy-box {
            .privacy-box-container {
                button {
                    font-size: 15px;
                    line-height: 20pt;
                }
                a.cta-btn.btn-wrapper {
                    width: unset;
                }
                .privacy-box-top {
                    padding: 40px 15px;
                    p {
                        font-size: 14px;
                        line-height: 20pt;
                        margin: 0 0 20px;
                    }
                }
                .privacy-box-bottom {
                    .text {
                        font-size: 14px;
                        line-height: 20pt;
                        margin: 0 0 14px;
                    }
                    h4 {
                        font-size: 18px;
                        line-height: 24pt;
                        margin: 0 0 14px;
                    }
                    .list {
                        font-size: 14px;
                        line-height: 18pt;
                        margin-bottom: 23px;
                        .list-item {
                            margin-bottom: 13px;
                            span.number {
                                height: 22px;
                                flex-basis: 22px;
                            }
                        }
                    }
                }
            }
        }
    }
}