//**
//**
// Variables
//**
@import './variables';
//**
//**
// Fonts
//**
@import './fonts';
//**
//**
// General Styles
//**
@import './general-styles';
//**
//**
// Components
//**
@import './components/action-button';
@import './components/header-navbar';
@import './components/header-navbar-mobile';
@import './components/dropdown-menu';
@import './components/language-selector';
@import './components/banner';
@import './components/simple-content-block';
@import './components/solutions-cross-linking';
@import './components/file-upload';
//**
//**
// Sections
//**
@import './sections/hero-section';
@import './sections/about-and-operation-section';
@import './sections/methodologies-section';
@import './sections/our-pilars-section';
@import './sections/footer-section';
@import './sections/mission-and-vision';
@import './sections/our-culture';
@import './sections/breadcrumb_section';
@import './sections/privacy-section';
@import './sections/arco-form-section';
@import './sections/contact_form_section';
@import './sections/improvement-control-info';
@import './sections/process-consulting-info';
@import './sections/improvement-control-section';
@import './sections/process-consulting-tabs';
body {
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
    section,
    header,
    div {
        box-sizing: border-box;
    }
}

div[location="/contact-us"] .pre-footer-section {
    display: none;
}

div[location="/privacy-notice"] {
    header {
        &.at-top {
            nav.header-navbar>a,
            .use-dropdown-menu button {
                color: $dark-text;
            }
        }
        .social-media-wrapper>a img {
            // re-color the white image into the branding color
            filter: invert(90%) sepia(10%) saturate(7492%) hue-rotate( 216deg) brightness(93%) contrast(99%);
        }
    }
}