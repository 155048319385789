section.hero-section {
    background-image: radial-gradient(100% 100% at 15.6% 12.3%, rgba(24, 24, 61, 0.97) 8%, rgba(24, 24, 61, 0.82) 31%, rgba(24, 24, 61, 0.67) 54%, rgba(24, 24, 61, 0.361) 100%), url('../../assets/heroBg.png');
    background-position: right center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 720px;
    width: 100%;
    display: grid;
    .container {
        max-width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        .Typewriter {
            min-height: 240px;
            display: flex;
            place-items: center;
        }
        h6 {
            color: $white;
            font-size: 26px;
            line-height: 35px;
            opacity: 0.9;
            margin: 0;
            font-family: $GilroyRegular;
        }
        h1 {
            color: $white;
            font-size: 90px;
            margin: 0 0 0;
            word-break: break-word;
            font-family: $GilroySemibold;
        }
    }
}

@media only screen and (max-width: 768px) {
    section.hero-section {
        height: 100vh;
        background-image: url('../../assets/heroBgMobile.png');
        .container {
            max-width: 100%;
            align-items: flex-start;
            text-align: left;
            padding: 0 20px;
            box-sizing: border-box;
            h6 {
                line-height: 28pt;
                margin: 0 0 6px;
                font-size: 22px;
            }
            h1 {
                font-size: 50px;
                margin: 0 0 30px;
            }
        }
    }
}