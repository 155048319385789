.banner {
    width: 100%;
    height: 442px;
    background: $dark-text url('../../assets/svg/footer-bg.svg') no-repeat center center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $white;
    padding: 0 20px;
    h1 {
        margin: 0 0 3px;
        font-size: 46px;
        line-height: 100pt;
        font-family: $GilroySemibold;
    }
    h3 {
        margin: 0;
        font-size: 18px;
        line-height: 24pt;
        font-family: $GilroyMedium;
    }
}

@media only screen and (max-width: 768px) {
    .banner {
        height: 330px;
        align-items: flex-start;
        h1 {
            margin: 0 0 5px;
            font-size: 32px;
            line-height: 36pt;
            font-family: $GilroyBold;
        }
        h3 {
            font-size: 16px;
            line-height: 22pt;
            font-family: $GilroyRegular;
            opacity: 0.9;
        }
    }
}