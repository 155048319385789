header {
    position: fixed;
    top: 0;
    height: 90px;
    width: 100%;
    padding: 36.6px 60px 36.6px 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    box-shadow: 0px 1px 0px rgba($black, 0.12);
    background-color: rgba($white, 0.6);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    opacity: 1;
    z-index: 5;
    a.header-logo {
        margin-right: auto;
    }
    nav.header-navbar {
        display: flex;
        &>a,
        .menu-wrapper>a,
        button {
            margin: 0 16px;
            font-size: 18px;
            line-height: 24pt;
            font-family: $GilroyRegular;
            color: $dark-text;
            text-decoration: none;
            opacity: 0.9;
            &:hover {
                text-decoration: underline;
            }
            &.active {
                color: $primary-red;
            }
        }
        button.language-selector {
            color: $primary-red;
        }
    }
    .social-media-wrapper {
        display: none;
        margin-left: 70px;
        &>a {
            margin-left: 23px;
            &:first-of-type {
                margin-left: 0;
            }
        }
    }
    a.cta-btn {
        margin-left: 70px;
    }
    &.at-top {
        box-shadow: none;
        background-color: transparent;
        backdrop-filter: unset;
        -webkit-backdrop-filter: unset;
        nav.header-navbar {
            &>a {
                color: $white;
                &.active {
                    color: $primary-red;
                }
            }
        }
        .social-media-wrapper {
            display: block;
        }
        .use-dropdown-menu {
            button {
                color: $white;
            }
        }
        a.cta-btn {
            display: none;
        }
    }
}

@media only screen and (max-width: 768px) {
    header {
        padding: 32.6px 20px;
    }
}