header {
    button {
        &.open-menu,
        &.close-menu {
            background-color: transparent;
            border: none;
            cursor: pointer;
        }
        &.open-menu img {
            filter: invert(100%);
        }
        &.close-menu {
            position: absolute;
            top: 20px;
            right: 20px;
            z-index: 120;
        }
    }
    .menu-panel {
        background-color: $white;
        height: 100vh;
        width: 238px;
        min-width: 60%;
        position: fixed;
        top: 0;
        right: 0;
        transform: translateX(150%);
        transition: all .3s ease-out;
        z-index: 100;
        box-shadow: -1px 0px 0px rgba($black, 0.12);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        display: grid;
        padding: 0 20px;
        &.open {
            transform: translateX(0);
        }
        .wrapper {
            margin: auto;
            width: 100%;
            text-align: center;
            nav.header-navbar-mobile {
                display: grid;
                row-gap: 37px;
                text-align: center;
                margin-bottom: 50px;
                a {
                    font-size: 18px;
                    line-height: 24pt;
                    font-family: $GilroyMedium;
                    opacity: 0.9;
                    color: $dark-text;
                    text-decoration: none;
                    z-index: 110;
                    &.active {
                        color: $primary-red;
                    }
                }
            }
            a.cta-btn {
                margin: 0 auto 50px;
            }
            .social-media-wrapper {
                display: flex;
                justify-content: space-evenly;
                margin: auto;
                align-items: center;
                a {
                    margin: auto;
                    img {
                        width: 23.8px;
                        height: 23.8px;
                    }
                }
            }
        }
    }
    &.at-top {
        button {
            &.open-menu img {
                filter: none;
            }
        }
        // override header-navbar hiding button desktop behavior
        .menu-panel {
            .wrapper {
                a.cta-btn {
                    display: flex;
                }
            }
        }
    }
}