section.arco-form {
    width: 100%;
    display: grid;
    height: 534px;
    margin-bottom: 1000px;
    background: radial-gradient(circle at 45% 90%, rgb(217, 228, 236) 0%, rgb(255, 255, 255) 100%);
    .arco-form-wrapper {
        margin-top: 100px;
        background: $white 0% 0% no-repeat padding-box;
        box-shadow: 8px 8px 0px $dark-gray;
        border: 2px solid $dark-gray;
        border-radius: 10px;
        padding: 50px 0;
        .container {
            .message-box {
                display: flex;
                justify-content: center;               
                text-align: left;
                font-family: $GilroyRegular;
                font-size: 20px;
                color: $dark-text;
                padding: 20px;
                margin-bottom: 20px;
                &.sucess {
                    background-color: #DFF2BF;
                }
                &.error {
                    background-color: #FFBABA;
                }
            }
            .row {
                display: flex;
                justify-content: center;
                .content-wrapper {
                    padding: 0 30px 0 30px;
                    h1 {
                        font-size: 50px;
                        text-align: left;
                        line-height: 54pt;
                        color: $dark-text;
                        margin: 40px 0 13px;
                        font-family: $GilroyBold;
                    }
                    form {
                        .form-wrapper {
                            display: grid;
                            grid-template-columns: 1.71fr 1fr;
                            grid-template-rows: 1fr 1fr 1fr 1fr 1fr 2fr 3fr 1fr 1fr;
                            gap: 30px 21px;
                            grid-auto-flow: row;
                            grid-template-areas: "datos-headline ." "full-name email" "address phone" "rights-headline ." "rights-input date" "reason reason" "attachment attachment" "download-link download-link" "agree submit";
                            .datos-headline {
                                grid-area: datos-headline;
                            }
                            .full-name {
                                grid-area: full-name;
                            }
                            .email {
                                grid-area: email;
                            }
                            .address {
                                grid-area: address;
                            }
                            .phone {
                                grid-area: phone;
                            }
                            .rights-headline {
                                grid-area: rights-headline;
                            }
                            .rights-input {
                                grid-area: rights-input;
                            }
                            .date {
                                grid-area: date;
                            }
                            .reason {
                                grid-area: reason;
                            }
                            .attachment {
                                grid-area: attachment;
                                
                            }
                            .download-link {
                                grid-area: download-link;
                            }
                            .agree {
                                grid-area: agree;
                            }
                            .submit {
                                grid-area: submit;
                                display: flex;
                                justify-content: flex-end;
                                align-items: flex-start;
                            }
                            h6.form-title {
                                font: normal normal bold 20px/26px $GilroyBold;
                                color: $dark-text;
                                margin: 0;
                            }
                            input[type="text"],
                            input[type="email"],
                            input[type="number"],
                            input[type="date"],
                            select {
                                background-color: $white;
                                border: 1px solid $gray;
                                border-radius: 6px;
                                height: 48px;
                                color: $dark-text;
                                font-family: $GilroyMedium;
                                font-size: 16px;
                                line-height: 26pt;
                                padding: 0 18.2px;
                                &::placeholder {
                                    opacity: 0.7;
                                }
                            }
                            textarea {
                                background: $white;
                                border: 1px solid #18183D33;
                                border-radius: 6px;
                                resize: none;
                                font-family: $GilroyMedium;
                                font-size: 16px;
                                line-height: 26pt;
                                color: $dark-text;
                                padding-left: 10px;
                                &::placeholder {
                                    opacity: 0.7;
                                }
                            }
                            label.agree {
                                margin-bottom: 20px;
                                font-family: $GilroyMedium;
                                font-size: 16px;
                                letter-spacing: 0px;
                                color: $dark-text;
                                opacity: 1;
                            }
                            .agree-container {
                                display: flex;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    section.arco-form {
        margin-bottom: 700px;
        &>.container {
            padding: 0 16px;
        }
        .arco-form-wrapper {
            padding: 30px 0;
            .container {
                padding: 0 15px;
                .row {
                    display: unset;
                    .content-wrapper {
                        padding: 0;
                        h1 {
                            font-size: 24px;
                            line-height: 30pt;
                            margin: 0 0 10px;
                        }
                        form {
                            .form-wrapper {
                                display: flex;
                                flex-direction: column;
                                grid-gap: 20px 0;
                                .submit {
                                    justify-content: flex-start;
                                }
                                h6.form-title {
                                    font: normal normal bold 16px/26px $GilroyBold;
                                }
                                input[type="text"],
                                input[type="email"] {
                                    height: 42px;
                                }
                                input.address {
                                    height: 75px;
                                }
                                textarea {
                                    height: 109px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}